/** @jsxImportSource theme-ui */
import React, { useState, useEffect } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import * as prismicH from "@prismicio/helpers";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Modal from "react-modal";
import { FaArrowRight, FaTimes } from "react-icons/fa";
import SEO from "../components/Seo";
import LightGallery from "lightgallery/react";
import { Global } from "@emotion/react";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { PrismicRichText } from "@prismicio/react";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.90)",
    zIndex: 20,
  },
};
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#___gatsby");

// markup
const PortfolioPage = ({ data }) => {
  const pageData = data.prismicPortfolioPage.data;

  const [modalState, setModalState] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [modalName, setModalName] = useState("");
  const [modalLink, setModalLink] = useState("");
  const [modalIndex, setModalIndex] = useState(0); //index of headshot open in original array

  const closeModal = () => {
    setModalState(false);
  };

  const onInit = () => {
    console.log("lightGallery has been initialized");
  };

  return (
    <Layout>
      <SEO
        title="Actor Headshots London Portraits"
        description="London headshot and portrait photographer portfolio. Professional headshots for actors. Studio Lighting"
      />
      <Global
        styles={{
          "& .lg-sub-html h3": {
            fontSize: "1.4rem",
            fontStyle: "normal",
            fontWeight: "400",
            px: 2,
            py: 2,
            backgroundColor: "white",
            color: "black",
            display: "inline-block",
            padding: "8px",
          },
        }}
      />
      <div>
        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            a: {
              textAlign: "right",
              textDecoration: "none",
              display: "inline-block",
              position: "relative",

              "&:hover": {
                textDecoration: "underline",
              },
            },
            "& svg": {
              display: "inline-block",
              position: "absolute",
              top: ["4px"],
              marginLeft: "4px",
              marginRight: "4px",
              // bottom: ["4px", "unset"],
            },
          }}
        >
          <h1>{pageData.title.text}</h1>
          <div sx={{ position: "relative" }}>
            <Link to="/portfolio-bw">Black & White Portraits</Link>
            <FaArrowRight />
          </div>
        </div>

        <div
          className="portfolioGrid"
          sx={{
            //had to change it to inside LightGallery
            "& .lg-react-element": {
              display: "grid",
              gridTemplateColumns: [
                "repeat(2, 1fr)",
                "repeat(3, 1fr)",
                "repeat(4, 1fr)",
              ],
              gridGap: 4,
              my: 4,
            },
          }}
        >
          <LightGallery onInit={onInit} speed={500} download={false}>
            {pageData.portfolio_grid.map((item, index) => {
              const image = getImage(item.image);
              return (
                <a
                  key={`portfolio-grid${index}`}
                  data-src={item.image.url}
                  data-sub-html={`<h3>${item.name.text}</h3>`}
                >
                  <figure
                    key={`portfolioItem${index}`}
                    data={index}
                    sx={headshotStyles}
                    //onClick={(e) => openModal(item, index)}
                  >
                    <GatsbyImage
                      image={image}
                      alt={item.image.alt}
                      sx={{
                        "& img": {
                          border: "1px solid black",
                          objectFit: "cover",
                        },
                      }}
                    />
                    <figcaption>{item.name.text}</figcaption>
                  </figure>
                </a>
              );
            })}
          </LightGallery>
        </div>

        <Modal
          isOpen={modalState}
          onRequestClose={closeModal}
          shouldCloseOnOverlayClick={true}
          style={modalStyles}
          contentLabel="Image Lightbox Modal"
        >
          <div
            sx={{
              color: "white",
              display: "flex",
              fontSize: "1.25rem",
              justifyContent: "right",
              cursor: "pointer",
              // marginRight: "2em",
              // marginBottom: "0.3em",
            }}
            onClick={closeModal}
          >
            <FaTimes />
          </div>
          <div
            sx={{
              //width: "1000px",
              maxWidth: "90vw",
              maxHeight: "90vh",
              textAlign: "center",
              "& .gatsby-image-wrapper": {
                width: "100%",
                maxWidth: "95vw",
                height: "100%",
                maxHeight: "85vh",
              },
              "& img": {
                //border: "1px solid black",
                objectFit: "contain !important",
              },
            }}
          >
            <GatsbyImage
              image={getImage(pageData.portfolio_grid[modalIndex].image)}
            />
          </div>
          <div
            sx={{
              display: "flex",
              pt: 3,
              justifyContent: "center",
              h2: { bg: "white", color: "black", m: 0, p: 2, lineHeight: 1 },
              a: {
                color: "white",
                fontSize: 5,
                ml: 2,
                lineHeight: 1,
                "&:hover, &:focus-within": { color: "muted" },
              },
              svg: { marginTop: "4px" },
            }}
          >
            <h2>{pageData.portfolio_grid[modalIndex].name.text}</h2>
            {!(
              pageData.portfolio_grid[modalIndex].link.link_type === "Any"
            ) && <a href="https://bbc.co.uk">{<FaArrowRight />}</a>}
          </div>
        </Modal>
      </div>
      <div sx={{ py: 4, fontSize: 4, color: "muted" }}>
        <PrismicRichText field={pageData.text.richText} />
      </div>
    </Layout>
  );
};

export default withPrismicPreview(PortfolioPage);

export const query = graphql`
  query portfolioPageQuery {
    prismicPortfolioPage {
      _previewable
      data {
        portfolio_grid {
          image {
            url
            alt
            gatsbyImageData(aspectRatio: 0.666, placeholder: BLURRED)
          }
          link {
            link_type
            slug
            url
            type
          }
          name {
            richText
            text
          }
        }
        text {
          richText
          text
        }
        title {
          richText
          text
        }
      }
      last_publication_date
    }
  }
`;

const headshotStyles = {
  m: 0,

  figcaption: {
    fontSize: "1.15rem",
    fontStyle: "italic",
  },
  "@media (hover: hover) and (pointer: fine)": {
    position: "relative",
    transition: "all 0.3s ease",
    cursor: "pointer",

    figcaption: {
      fontSize: "1.4rem",
      fontStyle: "normal",
      fontWeight: "400",
      position: "absolute",
      bottom: 2,
      left: 2,
      zIndex: 5,
      bg: "white",
      px: 2,
      py: 2,
      transition: "all 0.3s ease",
      transform: "translate(0,30px)",
      opacity: 0,
    },
    //hover
    "&:hover, &:focus-within": {
      figcaption: {
        transform: "translate(0,0)",
        opacity: 1,
      },
    },
  },
};
